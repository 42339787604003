<template>
  <div class="m-image-preview">
    <el-space wrap>
      <div class="img" v-for="img in imgList" :key="img">
        <el-image :src="img" :preview-src-list="imgList" :size="16" lazy fit="contain"></el-image>
      </div>
    </el-space>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    imgList: {
      type: Array as PropType<string[]>,
      require: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/tool';
.m-image-preview {
  .img {
    width: 80px;
    height: 80px;
    border-radius: $sr;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
  }
}
::v-deep(.el-image-viewer__canvas) {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>
